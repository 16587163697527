import type { Variant } from './Variant';
import type { Category } from './Category';
import type { BundleMode } from './Bundle';

export interface Product {
    key: string;
    productId?: string;
    changed?: Date;
    version?: string;
    name?: string;
    slug?: string;
    description?: string;
    categories?: Category[];
    variants: Variant[];
    _url?: string;
    productTypeName?: string;
    productTypeKey: ProductTypeKey;
    administrateLearningPathId?: string;
    masterVariantAdministrateCourseId?: string;
}

export enum ProductTypeKey {
    OTHER = 'other',
    TRAINING = 'training',
    MEMBERSHIP = 'membership',
    PROFESSIONAL_SERVICE = 'professional-service',
    BUNDLE = 'bundle'
}
export enum LineItemCustomFieldsTypeEnum {
    CHECKOUT_ORDER_LINE_ITEM = 'checkout-order-line-item',
    BUNDLE_LINE_ITEM = 'bundle-line-item',
    MEMBERSHIP_LINE_ITEM = 'membership-line-item',
    TRAINING_LINE_ITEM = 'training-line-item',
    PROFESSIONAL_SERVICE_LINE_ITEM = 'professional-service-line-item',
    OTHER_LINE_ITEM = 'other-line-item'
}

export type ProductTypeCustomFieldTypeMap = {
    [key in ProductTypeKey]: LineItemCustomFieldsTypeEnum;
};

export const ProductTypeCustomFields: ProductTypeCustomFieldTypeMap = {
    [ProductTypeKey.MEMBERSHIP]: LineItemCustomFieldsTypeEnum.MEMBERSHIP_LINE_ITEM,
    [ProductTypeKey.BUNDLE]: LineItemCustomFieldsTypeEnum.BUNDLE_LINE_ITEM,
    [ProductTypeKey.TRAINING]: LineItemCustomFieldsTypeEnum.TRAINING_LINE_ITEM,
    [ProductTypeKey.PROFESSIONAL_SERVICE]: LineItemCustomFieldsTypeEnum.PROFESSIONAL_SERVICE_LINE_ITEM,
    [ProductTypeKey.OTHER]: LineItemCustomFieldsTypeEnum.OTHER_LINE_ITEM
};

export interface Bundle {
    bundleMode?: BundleMode;
    allGroupName?: string;
    selectedBundleComponentVariantSKUs?: string[];
    selectedCommencementDate?: string;
}

export interface ProductType {
    id: string;
    key?: string;
    name: string;
    description: string;
    attributes?: ProductTypeAttribute[];
}

export interface ProductTypeAttribute {
    name: string;
    label: string;
    type: string;
    isRequired: boolean;
    isSearchable: boolean;
}
