import type { FC } from 'react';
import React, { useEffect, useState } from 'react';
import type { Product, Variant } from '@wilm/shared-types/product';
import { AttributeEnum } from '@wilm/shared-types/product/Attributes';
import type { LineItemAddToCartAttributes } from '@wilm/shared-types/sales-link/SalesLinkCart';
import Dropdown from 'components/commercetools-ui/atoms/dropdown';
import BundleComponentSelectorTile from 'components/sales-link/organisms/content/products/bundle-component-selector/tile';

interface SelectGroupContentProps {
    dropdownBundleOptions: { label: string; value: string }[];
    selectedGroupError: string;
    selectedAttributes: LineItemAddToCartAttributes;
    setSelectedAttributes: (attributes: Record<string, any>) => void;
    selectedProductVariants: { productId: string; productName: string; variant?: Variant; variants?: Variant[] }[];
    components: Product[];
}

const SelectGroupContent: FC<SelectGroupContentProps> = ({
    dropdownBundleOptions,
    selectedGroupError,
    selectedAttributes,
    setSelectedAttributes,
    selectedProductVariants,
    components
}) => {
    const [selectedProductVariantsState, setSelectedProductVariantsState] = useState(selectedProductVariants);

    const [expandedVariants, setExpandedVariants] = useState<Set<string>>(new Set());

    useEffect(() => {
        const variants = [];
        for (const variant of selectedProductVariants) {
            if (expandedVariants.has(variant.productId)) {
                const product = components.find(component => component.productId === variant.productId)!;

                variants.push({
                    productId: product.productId!,
                    productName: product.name!,
                    variants: product.variants
                });
            } else {
                variants.push(variant);
            }
        }

        setSelectedProductVariantsState(variants);
    }, [selectedProductVariants, components, expandedVariants]);

    const handleGroupSelection = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value ? JSON.parse(e.target.value) : null;

        const selectedBundleComponentVariantSKUs: Record<string, string> = {};

        for (const group of value) {
            selectedBundleComponentVariantSKUs[group.productId] = group.productSku;
        }

        setSelectedAttributes({
            ...selectedAttributes,
            selectedBundleComponentVariantSKUs
        });

        setExpandedVariants(new Set());
    };

    const showAllVariantsForProduct = (productId: string) => {
        const product = components.find(component => component.productId === productId);

        if (!product) {
            return;
        }

        setExpandedVariants(prev => {
            prev.add(productId);
            return prev;
        });

        const newSelectedProductVariantsState = selectedProductVariantsState.map(variant => {
            if (variant.productId === productId) {
                return {
                    productId: product.productId!,
                    productName: product.name!,
                    variants: product.variants
                };
            }
            return variant;
        });
        setSelectedProductVariantsState(newSelectedProductVariantsState);
    };

    const changeComponentSelect = (selectedProduct: Product, selectedVariant: Variant) => {
        setExpandedVariants(prev => {
            prev.delete(selectedProduct.productId!);
            return prev;
        });
        setSelectedAttributes({
            ...selectedAttributes,
            selectedBundleComponentVariantSKUs: {
                ...(selectedAttributes.selectedBundleComponentVariantSKUs as Record<string, string>),
                [selectedProduct.productId!]: selectedVariant.sku
            }
        });
    };

    return (
        <div className="mb-40">
            <Dropdown
                items={dropdownBundleOptions}
                label="Select bundle option"
                defaultValue=""
                required={true}
                name="bundle-option"
                id="bundle-option"
                onChange={handleGroupSelection}
                errorMessage={selectedGroupError}
            ></Dropdown>

            {selectedProductVariantsState.map(product => (
                <div key={product.productId} className="mt-20">
                    <BundleComponentSelectorTile
                        key={product.productId}
                        product={
                            {
                                productId: product.productId,
                                name: product.productName,
                                variants: product.variants ?? [product.variant]
                            } as Product
                        }
                        selectedSkus={selectedAttributes.selectedBundleComponentVariantSKUs}
                        attributesToShow={[
                            AttributeEnum.SKU,
                            AttributeEnum.LEARNING_MODE,
                            AttributeEnum.EVENT_TYPE,
                            AttributeEnum.START_DATE,
                            AttributeEnum.END_DATE
                        ]}
                        onSelect={changeComponentSelect}
                        showChangeButton={!expandedVariants.has(product.productId)}
                        onChange={showAllVariantsForProduct}
                        showVariantOutOfStockAsError={true}
                    />
                </div>
            ))}
        </div>
    );
};

export default SelectGroupContent;
