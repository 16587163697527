import React, { useState } from 'react';
import type { FC } from 'react';
import Button from 'components/commercetools-ui/atoms/button';
import Modal from 'components/commercetools-ui/atoms/modal';

export interface Props {
    isOpen: boolean;
    closeModal: () => void;
    paymentLink: string;
}

const PaymentLinkModal: FC<Props> = ({ isOpen, closeModal, paymentLink }) => {
    const [buttonText, setButtonText] = useState('Copy Link');
    const [copyError, setCopyError] = useState(false);
    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(paymentLink);
            setButtonText('Copied');
        } catch (error) {
            setCopyError(true);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            className="relative w-[90%] rounded-md bg-white"
            style={{ content: { maxWidth: '600px' } }}
            preventScroll={true}
        >
            <div className="p-16 text-base md:p-40">
                <p className="mb-20 text-lg font-bold">Customer payment link:</p>
                <p className="mb-24 break-words rounded-md bg-neutral-200 p-12 pt-6">{paymentLink}</p>

                <div className="flex justify-end">
                    <Button onClick={handleCopy} className="mr-12">
                        {buttonText}
                    </Button>
                    <Button variant="secondary" onClick={closeModal}>
                        Close
                    </Button>
                </div>

                {copyError && <p className="mt-10 text-sm text-input-error">Unable to copy from keyboard</p>}
            </div>
        </Modal>
    );
};

export default PaymentLinkModal;
