export interface FieldDefinition {
    name: string;
    label?: string;
    disabled?: boolean;
    showOnPredicate?: (fields: Fields) => boolean; // If this field should be shown based on other fields
    validation: FieldDefinitionValidation;
}

interface FieldDefinitionValidation {
    required?: boolean;
    regex?: RegExp;
    min?: number;
    max?: number;
    minLength?: number;
    maxLength?: number;
    errorMessages?: {
        validation?: string;
        range?: string;
        regex?: string;
    };
    minPredicate?: (fields: Fields) => string;
    sameAsPredicate?: (fields: Fields) => boolean;
    requiredPredicate?: (fields: Fields) => boolean; // If this field is required based on other fields
    validate?: (fields: Fields) => FieldError;
}
export type FieldError = { showError?: boolean; message?: string };
export type FieldErrors = {
    [key in keyof Fields]?: FieldError;
};

export interface BooleanFieldDefinition extends FieldDefinition {
    type: FieldType.BOOLEAN;
    value: boolean;
}

export interface StringFieldDefinition extends FieldDefinition {
    type: FieldType.STRING;
    value: string;
}

export interface EnumFieldDefinition extends FieldDefinition {
    type: FieldType.ENUM;
    options: { label: string; value: string }[];
    getOptions?: (fields: Fields) => { label: string; value: string }[];
    value: string;
}

export interface DateFieldDefinition extends FieldDefinition {
    type: FieldType.DATE;
    value: string;
}

export interface NumberFieldDefinition extends FieldDefinition {
    type: FieldType.NUMBER;
    value: number;
}

export enum FieldType {
    BOOLEAN = 'boolean',
    STRING = 'string',
    ENUM = 'enum',
    DATE = 'datetime-local',
    NUMBER = 'number'
}

export type Field = BooleanFieldDefinition | StringFieldDefinition | EnumFieldDefinition | DateFieldDefinition | NumberFieldDefinition;

export type Fields = Record<string, Field>;

export class FieldsValidationError extends Error {
    protected code?: string;
    errors: FieldErrors;

    constructor({ errors }: { errors?: FieldErrors }) {
        super('Form has incorrect fields');

        this.code = 'FORM_VALIDATION_ERROR';
        this.errors = errors ?? {};
    }
}
