import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CheckIcon } from '@heroicons/react/24/outline';
import useClassNames from 'helpers/hooks/useClassNames';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';

export interface CheckboxProps extends Omit<React.ComponentProps<'input'>, 'onChange' | 'onMouseOver' | 'onMouseLeave'> {
    label?: string | React.JSX.Element;
    labelPosition?: 'on-left' | 'on-right';
    containerClassName?: string;
    onChange?: (props: { name: string; checked: boolean }) => void;
    disableBackground?: boolean;
    noIcon?: boolean;
    smallLabel?: boolean;
    onMouseOver?: React.MouseEventHandler;
    onMouseLeave?: React.MouseEventHandler;
}

const Checkbox: React.FC<CheckboxProps> = ({
    className = '',
    checked,
    onChange,
    onMouseOver,
    onMouseLeave,
    containerClassName,
    label,
    noIcon = true,
    defaultChecked = true,
    labelPosition = 'on-right',
    disableBackground = true,
    smallLabel = false,
    ...props
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [isChecked, setIsChecked] = useState(checked ?? defaultChecked ?? false);

    const [isDesktopSize] = useMediaQuery(desktop);

    const checkboxRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (typeof checked !== 'undefined') setIsChecked(checked);
    }, [checked]);

    const toggleIsChecked = useCallback(() => {
        onChange?.({ name: props.name ?? '', checked: !isChecked });
        setIsChecked(!isChecked);
    }, [isChecked, onChange, props.name]);

    const handleMouseOver = (e: React.MouseEvent) => {
        setIsHovered(isDesktopSize);
        onMouseOver?.(e);
    };

    const handleMouseLeave = (e: React.MouseEvent) => {
        setIsHovered(false);
        onMouseLeave?.(e);
    };

    const containerClassNames = useClassNames(['flex gap-12 text-left', containerClassName]);
    const buttonClassName = useClassNames([
        'cursor-cursor-pointer relative h-[20px] w-[20px] min-w-[20px] rounded-md border border-input-border',
        {
            'outline outline-2 outline-offset-[2px]': isFocused
        },
        {
            'shadow-bottom': isHovered
        }
    ]);

    const inputClassName = useClassNames(['absolute z-[2] h-[20px] w-[20px] opacity-0', className]);

    const iconClassName = useClassNames([
        'absolute left-[50%] top-[50%] z-[1] h-16 w-16 translate-x-[-50%] translate-y-[-50%] stroke-[2px]',
        isChecked || isHovered ? 'block' : 'hidden',
        isChecked && !disableBackground ? 'text-white' : 'text-secondary-black'
    ]);

    const backgroundClassName = useClassNames(['absolute z-0 h-[20px] w-[20px] rounded-sm', { 'bg-secondary-black': !!isChecked }]);

    const noIconClassName = useClassNames([
        'absolute absolute left-[50%] left-[50%] top-[50%] top-[50%] z-0 z-0 z-[1] z-[1] h-10 h-[10px] w-10 w-[10px] translate-x-[-50%] translate-x-[-50%] translate-y-[-50%] translate-y-[-50%] rounded-md rounded-md',
        { 'bg-input-border': !!isChecked }
    ]);

    const LabelElement = (
        <label htmlFor={props.name} className={`cursor-pointer text-input-text ${smallLabel ? 'mt-4 text-sm' : 'text-md'}`}>
            {label}
        </label>
    );

    return (
        <div className={containerClassNames}>
            {label && labelPosition === 'on-left' && LabelElement}

            <div className={buttonClassName}>
                <input
                    ref={checkboxRef}
                    type="checkbox"
                    checked={isChecked}
                    className={inputClassName}
                    onMouseOver={handleMouseOver}
                    onMouseLeave={handleMouseLeave}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onChange={toggleIsChecked}
                    id={props.name}
                    {...props}
                />
                {noIcon ? <div className={noIconClassName}></div> : <CheckIcon className={iconClassName} />}

                {!disableBackground && <div className={backgroundClassName} />}
            </div>

            {label && labelPosition === 'on-right' && LabelElement}
        </div>
    );
};

export default Checkbox;
